
































import KCard from '@/@core/components/other/KCard.vue'
import KWrapper from '@/@core/components/other/KWrapper.vue'
import KPage from '@/@core/components/page/KPage.vue'
import KPageBody from '@/@core/components/page/KPageBody.vue'
import KPageHeader from '@/@core/components/page/KPageHeader.vue'
import KText from '@/@core/components/typography/KText.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { KPage, KPageHeader, KText, KPageBody, KWrapper, HelloWorld, KCard },
    name: 'LandingDefaultPage',
    setup() {
        //

        return {
            //
        }
    }
})
